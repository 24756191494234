import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const Navigation = ({ toggle }) => {
  const menuitems = ["HOME", "GALLERY", "AYURVEDHA", "ACCOMMODATION", "FACILITIES",  "CONTACT US"];
  const routes = ["/", "/gallery", "/ayurvedha", "/accommodation", "/facilities",  "/contact"];

  return (
    <motion.ul variants={variants} className="p-[25px] absolute top-[70px] w-[230px]">
      {menuitems.map((item, index) => (
        <motion.li
          key={index}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="flex list-none mb-[20px] items-center cursor-pointer text-white"
        >
          <Link to={routes[index]} onClick={toggle}
          >
            <div>{item}</div>
          </Link>
        </motion.li>
      ))}
    </motion.ul>
  );
};
