import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react'

export default function PasswordForm({ pass, passValidationSchema, loading, updatePassword }) {
  return (
    <Formik
      initialValues={pass}
      validationSchema={passValidationSchema}
      onSubmit={(values) => {
        updatePassword(values);
      }}
      className=""
    >
      <Form className="grid grid-cols-1 gap-9 sm:grid-cols-2">
        <div className="flex flex-col gap-4 ">
          <div className="p-6.5">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                Current Password<span className="text-meta-1">*</span>
              </label>
              <Field
                placeholder=""
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="oldPass"
              />
              <ErrorMessage
                component="a"
                className="text-danger"
                name="oldPass"
              />
            </div>
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                New Password <span className="text-meta-1">*</span>
              </label>
              <Field
                placeholder=""
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="newPass"
              />
              <ErrorMessage
                component="a"
                className="text-danger"
                name="newPass"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-9 h-auto">
          <div className="p-6.5">
            <div className="mb-4.5">
              <label className="mb-2.5 block text-black dark:text-white">
                Confirm Password <span className="text-meta-1">*</span>
              </label>
              <Field
                placeholder=""
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="confPass"
              />
              <ErrorMessage
                component="a"
                className="text-danger"
                name="confPass"
              />
            </div>
            <div className="mb-4.5 mt-14">
              <button
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Update Your Password"
                )}
              </button>
            </div>
          </div>
        </div>
      </Form >
    </Formik >
  )
}

