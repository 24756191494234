import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { AuthContext } from "../../../utils/AuthContext";
import { BLOGS } from "../../../api/blogs"; // Replace with your blogs API
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";

export default function BlogList() {
  const { checkAccessTokenValidity } = UseAuth();
  const { user, token } = useContext(AuthContext);
  const IMG_PATH = "/dbImages/blogs/"; // Update path if needed
  const [refresh, setRefresh] = useState(false);
  const [blog, setBlog] = useState([]);
  const itemsPerPage = 20;

  // Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = blog.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(blog.length / itemsPerPage);

  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/updateBlog/${id}`); // Update route for blog editing
  };
  const handleView = (id) => {
    navigate(`/viewBlog/${id}`); // Update route for viewing a blog
  };
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this blog! Are you sure?"
    );

    if (confirmed) {
      try {
        const res = await BLOGS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch blog information");
        } else {
          deleteData(res.data, id);
        }
      } catch (error) {
        console.log("Error loading blogs: ", error);
      }
    }
  };

  const deleteData = async (req, id) => {
    try {
      const updateRes = await BLOGS.DELETE(req, id, token);
      if (updateRes.status === 200) {
        setRefresh(!refresh);
        toast.success("Deleted blog");
        navigate("/BlogList");
      } else {
        toast.error("Failed to delete blog");
        throw new Error("Failed to delete blog");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getBlogList = async () => {
    try {
      const res = await BLOGS.GET_ALL(token);
      if (res.status !== 200) {
        throw new Error("Failed to fetch blog information");
      }
      setBlog(res.data);
    } catch (error) {
      console.log("Error loading blogs: ", error);
      if (error.response.status === 401) {
        checkAccessTokenValidity();
        setRefresh(!refresh);
      }
    }
  };

  useEffect(() => {
    getBlogList();
  }, [refresh]);

  return (
    <div>
      <Breadcrumbs pageName="Blog List" />
      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                  Title
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Description
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems !== undefined && (
                <>
                  {currentItems.map((data) => (
                    <tr key={data._id}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.title}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.description}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() => handleView(data._id)}
                          >
                            {/* View SVG icon */}
                          </button>
                          <button
                            className="hover:text-primary"
                            onClick={() => handleEdit(data._id)}
                          >
                            {/* Edit SVG icon */}
                          </button>
                          <button
                            className="hover:text-primary"
                            onClick={() => handleDelete(data._id)}
                          >
                            {/* Delete SVG icon */}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <PaginatedItems
          itemsPerPage={itemsPerPage}
          array={blog}
          setItemOffset={setItemOffset}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
}
