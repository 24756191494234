import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { CONTACT } from "../../../api/contact";
export default function ContactView() {
  let { id } = useParams();
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const getContact = async (id) => {
    try {
      const res = await CONTACT.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch contact information");
      }
      console.log("s", res)
      setData({
        ...data,
        _id: res.data._id,
        name: res.data.name,
        email: res.data.email,
        message: res.data.message,
      });
      data._id = res.data._id;
      data.name = res.data.name;
      data.email = res.data.email;

      data.message = res.data.message;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    getContact(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="View Conatct" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            {data.name}
          </h3>
        </div>
        <div className="grid grid-cols-1  sm:grid-cols-2">
          <div className="flex flex-col gap-9 ">
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Name <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.name}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-9 ">
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Email <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.email}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-9 h-auto col-span-2">
            <div className="px-6.5 h-auto">
              <div className="mb-8 flex flex-col gap-6 xl:flex-row ">
                <div className="w-full ">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Message
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex items-center justify-center w-full">
                    <p
                      name="message"
                      rows="4"
                      placeholder="Please explain your enquiry"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    >  {data.message}
                    </p >

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}