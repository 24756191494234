import { useContext, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ADMIN } from "../../../api/admin";
import { TokenContext } from "../../../utils/TokenContext";
import Select from "react-select";
import { UseAuth } from "../../../utils/UseAuth";
export default function EmpUpdate() {
  const { checkAccessTokenValidity } = UseAuth();
  let { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useContext(TokenContext);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [data, setData] = useState({
    _id: "",
    name: "",
    password: "",
    role: 0,
    tokenId: "",
    iniData: { value: 0, label: "Super Admin" },
    isPurged: false,
  });
  const options = [
    { value: 0, label: "Super Admin" },
    { value: 1, label: "Admin" },
    { value: 2, label: "Booking Clerk" },
    { value: 3, label: "Ticket Inspector" },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    role: Yup.number().required("Required"),
  });

  const SelectField = ({ options, form, field, ...props }) => {
    return (
      <Select
        {...props}
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        // onBlur={field.onBlur}
      />
    );
  };

  const updateData = async (values, id) => {
    setLoading(true);

    try {
      const res = await ADMIN.UPDATE(values, id, token);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Updated admin");
        navigate("/admin");
      } else {
        toast.error("Failed to updated admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };

  const handleSubmit = async () => {
    console.log("first");
    if (data.name !== "") {
      setLoading(true);
      var req = {
        name: data.name,
        password: data.password,
        role: data.role,
        tokenId: data.tokenId,
        isPurged: false,
      };
      updateData(req, id);
    } else {
      console.log("first");
    }
  };

  useEffect(() => {
    if (reRunData !== null) updateData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  const getEmp = async (id) => {
    try {
      const res = await ADMIN.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch employee information");
      }
      var iniData = { value: 0, label: "Super Admin" };
      if (res.data[0].role === 0) {
        iniData = { value: 0, label: "Super Admin" };
      } else if (res.data[0].role === 1) {
        iniData = { value: 1, label: "Admin" };
      } else if (res.data[0].role === 2) {
        iniData = { value: 2, label: "Booking Clerk" };
      } else if (res.data[0].role === 3) {
        iniData = { value: 3, label: "Ticket Inspector" };
      }
      setData({
        ...data,
        _id: res.data[0]._id,
        name: res.data[0].name,
        password: res.data[0].password,
        role: res.data[0].role,
        tokenId: res.data[0].tokenId,
        isPurged: res.data[0].isPurged,
        iniData: iniData,
      });

      data._id = res.data[0]._id;
      data.name = res.data[0].name;
      data.password = res.data[0].password;
      data.role = res.data[0].role;
      data.tokenId = res.data[0].tokenId;
      data.isPurged = res.data[0].isPurged;
      data.iniData = iniData;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  console.log("data", data);
  const resetPassword = () => {
    var req = {
      name: data.name,
      password: data.name + "@" + "2024",
      role: data.role,
      tokenId: data.tokenId,
      isPurged: false,
    };
    updateData(req, data._id);
  };
  useEffect(() => {
    getEmp(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="Update Employee" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Update Details
          </h3>
        </div>
        {/* <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        > */}
        <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
          <div className="flex flex-col ">
            <div className="px-6.5 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                User Name <span className="text-meta-1">*</span>
              </label>
              {/* <Field
                  placeholder="Enter the user name"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="name"
                /> */}
              <input
                type="text"
                placeholder="Enter the user name"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="name"
                value={data.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
              />
              {data.name === "" && <p className="text-red">* Required</p>}
              {/* <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="name"
                /> */}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Status <span className="text-meta-1">*</span>
              </label>
              {/* <Field name="role" component={SelectField} options={options} /> */}
              <Select
                value={data.iniData}
                options={options}
                onChange={(e) => {
                  var iniData = { value: 0, label: "Super Admin" };
                  if (e.value === 0) {
                    iniData = { value: 0, label: "Super Admin" };
                  } else if (e.value === 1) {
                    iniData = { value: 1, label: "Admin" };
                  } else if (e.value === 2) {
                    iniData = { value: 2, label: "Booking Clerk" };
                  } else if (e.value === 3) {
                    iniData = { value: 3, label: "Ticket Inspector" };
                  }
                  setData({
                    ...data,
                    role: e.value,
                    iniData: iniData,
                  });
                  data.role = e.value;
                  data.iniData = iniData;
                }}
              />
              {/* <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="role"
                /> */}
            </div>
          </div>

          <div className="p-6.5">
            <button
              type="button"
              onClick={() => {
                resetPassword();
              }}
              className="flex w-full justify-center rounded bg-white border-2 border-primary p-3 font-medium text-primary"
            >
              {loading ? (
                <div className="flex h-auto items-center justify-center ">
                  <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                </div>
              ) : (
                "Reset to Default Password"
              )}
            </button>
          </div>
          <div className="p-6.5">
            <button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
            >
              {loading ? (
                <div className="flex h-auto items-center justify-center ">
                  <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                </div>
              ) : (
                "Update Employee"
              )}
            </button>
          </div>
        </div>
        {/* </Formik> */}
      </div>
    </div>
  );
}
