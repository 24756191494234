import React from "react";
// import DarkModeSwitcher from "./DarkModeSwitcher";
// import DropdownUser from "./DropdownUser";
import { Navbar } from "./navbar/Navbar";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { HOME } from "../../assets";
import { Link } from "react-router-dom";


export default function Header(props) {
  const [secondRef, inSecond] = useInView({ triggerOnce: false });
  return (

    <header className="z-999 absolute w-full h-auto pb-2 bg-gradient-to-b from-black to-transparent flex flex-row items-center justify-between px-[5px] lg:px-2.5 ">
      {/* <div className="flex flex-grow items-center justify-between px-4 py-4"> */}
      <div className="flex items-center">
        <Navbar />
      </div>
      <div className="flex items-center ml-15 z-999">
        <Link to="/">
          <img src={HOME.LOGO} className="h-14 pt-[5px] md:h-24 lg:h-25" />
        </Link>
      </div>
      <div ref={secondRef}>
        <motion.button
          whileHover={{
            scale: 1.1,
            textShadow: "0px 0px 8px rgb(255,255,255)",
            boxShadow: "0px 0px 8px rgb(255,255,255)",
          }}
          className="bg-[#00A1ED] mt-2 md:mt-0 text-white text-[10px] md:text-sm rounded-full text-sm px-[20px] md:px-8 py-[7px] md:py-3 font-poppins font-thin tracking-wide"
        >
          BOOK NOW
        </motion.button>
      </div>
      {/* </div> */}
    </header>
  );
}
