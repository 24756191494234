import React, { useState, useContext } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import PasswordForm from "./Passwordform";
import { AuthContext } from "../../../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import ProfileView from "./ProfileView";
import { TokenContext } from "../../../utils/TokenContext";
import { UseAuth } from "../../../utils/UseAuth";
import { ADMIN } from "../../../api/admin";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

export default function ProfileScreen() {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(TokenContext);
  const { checkAccessTokenValidity } = UseAuth();
  const router = useNavigate();
  const [data] = useState({
    name: user.name,
    password: user.password,
    role: user.role,
    tokenId: user.tokenId,
    isPurged: false,
  });
  const [pass] = useState({
    oldPass: "",
    newPass: "",
    confPass: "",
  });
  const passValidationSchema = Yup.object().shape({
    oldPass: Yup.string().required("Old password is required"),
    newPass: Yup.string().required("New Password is required"),
    confPass: Yup.string()
      .oneOf([Yup.ref("newPass"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const updatePassword = async (values) => {
    setLoading(true);

    const resp = await ADMIN.PASS_CHECK(user._id, {
      password: values.oldPass,
    });
    const passwordsMatch = resp.data.data;
    if (!passwordsMatch) {
      toast.error("Incorrect password!");
      setLoading(false);
      return null;
    } else {
      setLoading(false);
      var request = {
        password: values.newPass,
        name: user.name,
        tokenId: user.tokenId,
        role: user.role,
      };
      updateUser(request);
    }
  };
  const updateUser = async (request) => {
    try {
      const res = await ADMIN.UPDATE(request, user._id, token);
      if (res.status === 200) {
        setLoading(false);
        setUser(res.data);
        toast.success("Updated Password");
        router.push("/profile");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        checkAccessTokenValidity();
        toast.warn("Server Reloading! Try Again.");
        toast.error("Failed to update infromation");
      }
    }
  };

  return (
    <div>
      <Breadcrumbs pageName="Profile" />
      <div className="grid grid-cols-1 h-auto w-auto gap-10">
        <div className=" rounded-md border border-stroke  shadow-default dark:border-strokedark dark:bg-boxdark  ">
          <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark ">
            <h3 className="font-medium text-black dark:text-white">
              Password Update
            </h3>
          </div>
          <PasswordForm
            pass={pass}
            passValidationSchema={passValidationSchema}
            loading={loading}
            updatePassword={updatePassword}
          />
        </div>
      </div>
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark mt-10">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Update your Profile
          </h3>
        </div>
        <ProfileView data={data} />
      </div>
    </div>
  );
}
