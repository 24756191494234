import React, { useState } from "react";
import { motion } from "framer-motion";
import { HOME } from "../../../assets";

export default function Banner() {
  // const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="w-full h-full relative">
      <div className="h-full w-full flex justify-center">
        <div
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
          className=" w-full h-[830px]"
        >
          <img src={HOME.BANNER_IMG} className="w-full h-full object-cover" />
        </div>
        {/* {isHovered ? (
          <motion.div
            initial={{ y: "-100vw" }}
            animate={{ y: 0 }}
            className="absolute w-full h-screen bg-black  bg-opacity-50"
          ></motion.div>
        ) : (
          ""
        )} */}
        <div className="w-full h-full bg-white absolute top-0 lg:opacity-45 opacity-55 "></div>
        <div className="absolute w-[65%] py-[100px] h-auto flex flex-col lg:flex-row  max-w-screen-2xl justify-center">
          <div className="flex justify-center flex-col lg:flex-row">
            {/* <div className="backdrop-blur-[15px] flex items-center justify-center flex-col lg:flex-row"> */}
            <p className="lg:w-[45%] w-full 2xl:text-5xl lg:text-4xl md:text-4xl text-3xl font-felixTitling lg:mr-8">
              Luxury, Business, and Leisure Perfected
            </p>
            <p className="lg:w-[55%]  w-full lg:text-xl text-lg font-poppins lg:font-light font-semibold  leading-7  lg:py-0 md:py-10 py-5">
              Experience exceptional comfort with our diverse room options, including Luxury Suites and
              Premium Rooms. Enjoy versatile event spaces, from grand Banquet Halls to high-tech Conference
              Rooms. Relax at our Ayurvedic Spa and savor fine dining, all designed to warmly welcome you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
