import React, { useEffect } from 'react'
import { IMG } from "../../assets";
// import TrackVisibility from "../../utils/TrackVisibility"
// import 'animate.css';

export default function Activities() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const activities = [
    {
      title: "Canoeing",
      description: " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. Mauris est magna, bibendum et sagittis in, tristique at nunc. Nulla sed lacinia neque.",
      imgUrl: IMG.ACT_BOAT,
    },
    {
      title: "Swimming Pool",
      description: " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. Mauris est magna, bibendum et sagittis in, tristique at nunc. Nulla sed lacinia neque.",
      imgUrl: IMG.ACT_SWIM,
    },
    {
      title: "Pedel Boating",
      description: " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. Mauris est magna, bibendum et sagittis in, tristique at nunc. Nulla sed lacinia neque.",
      imgUrl: IMG.ACT_PEDEL,
    },
    {
      title: "Indoor and Outdoor Games",
      description: " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. Mauris est magna, bibendum et sagittis in, tristique at nunc. Nulla sed lacinia neque.",
      imgUrl: IMG.ACT_YOGA,
    },
    {
      title: "Children's Play Area",
      description: " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. Mauris est magna, bibendum et sagittis in, tristique at nunc. Nulla sed lacinia neque.",
      imgUrl: IMG.ACT_PLAY,
    },
    {
      title: "Angling",
      description: " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. Mauris est magna, bibendum et sagittis in, tristique at nunc. Nulla sed lacinia neque.",
      imgUrl: IMG.ACT_ANGLING,
    },
  ];
  return (
    <div className="h-auto w-full">
      <div>
        <div className=" w-full h-full md:h-100">
          <img
            src={IMG.ACT_B}
            alt="images"
            style={{ width: "100%", height: "100%" }}
            className=" object-cover"
          />
        </div>
        <div className="w-full py-20 px-30 bg-[#F6F9F8]">
          <h2 className="text-3xl font-bold font-felixtitling text-center text-black">Activities</h2>
          <p className="text-center font-poppins text-gray-400 mt-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </p>
          <div className="mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {activities.map((activity, index) => (
                <div key={index} className="w-full">
                  <div className="rounded-lg  shadow-lg">
                    <div className='h-100 w-100 rounded-lg'>
                    <img className="w-full h-full object-cover" src={activity.imgUrl} alt={activity.title} />
                    </div>
                    <div className="p-4">
                      <h4 className="text-xl font-semibold text-gray-400">{activity.title}</h4>
                      <span className="text-gray-400">{activity.description}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>



  )
}
