import React from "react";
import { HOME } from "../../../assets";
import { motion, Variants } from "framer-motion";
import splitStringUsingRegex from "../../../utils/splitStringUsingRegex";

export default function InfoSection() {
  //   const heading = "Welcome to Leisures of Luxury and Relaxation";
  //   const textdata =
  //     " Step into Rustic Leisures, where luxury, business, and leisure blend seamlessly. Enjoy an exceptional experience that promises comfort and rejuvenation, catering to all your needs, whether you're here for holidays, travel, or work";
  //   const headingChars = splitStringUsingRegex(heading);
  //   const textDataChars = splitStringUsingRegex(textdata);
  //   const heading2 = "Exquisite Dining for Every Occasion";
  //   const textdata2 = " Enjoy a fine dining experience at our hotel, where each meal is a celebration of refined flavors and elegance, perfectly complementing your stay with us"
  //   const headingChars2 = splitStringUsingRegex(heading2);
  //   const textDataChars2 = splitStringUsingRegex(textdata2);

  const array = [
    {
      id: 1,
      heading: "Welcome to Leisures of Luxury and Relaxation",
      heading2: "Exquisite Dining for Every Occasion",
      textdata:
        " Step into Rustic Leisures, where luxury, business, and leisure blend seamlessly. Enjoy an exceptional experience that promises comfort and rejuvenation, catering to all your needs, whether you're here for holidays, travel, or work",
      textdata2:
        " Enjoy a fine dining experience at our hotel, where each meal is a celebration of refined flavors and elegance, perfectly complementing your stay with us",
    },
  ];

  const charVariants = {
    hidden: { opacity: 0, y: 20 }, // Adjust animation properties as needed
    reveal: { opacity: 1, y: 0 },
  };
  return (
    <div className="w-full h-full flex justify-center items-center px-[20px] sm:px-[50px] lg:py-0 lg:pt-5 px-[50px] xl:px-[110px] 2xl:px-[150px]">
      <div className="w-[95%] h-full max-w-screen-2xl py-5 sm:py-0 flex flex-col gap-0 sm:gap-10 pt-5 lg:flex-row gap-5">
        <div className="w-full grid lg:grid-rows-5 gap-10 sm:gap-0 lg:gap-10">
          <div className="lg:row-span-2 w-full flex flex-col gap-2 pt-0 sm:pt-15 lg:pt-15 w-[95%] xl:w-[90%] pt-20 2xl:pt-30 lg:pb-20 md:pb-15">
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="lg:text-4xl text-3xl font-felixTitling"
            >
              {array.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char.heading2}
                </motion.span>
              ))}
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="md:text-2xl lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7"
            >
              {array.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char.textdata2}
                </motion.span>
              ))}
            </motion.p>
          </div>
          <div className="row-span-3 w-full flex justify-center md:justify-start">
            <div className="w-full lg:w-[95%] xl:w-[90%] md:h-[570px] h-[350px]">
              <motion.img
                whileHover={{ scale: 1.02 }}
                src={HOME.INFO_IMG2}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className="w-full grid md:grid-rows-5 sm:gap-10 lg:mt-0 md:mt-8">
          <div className="row-span-3 w-full flex justify-center md:justify-end">
            <div className="w-full lg:w-[95%] xl:w-[90%] 2xl:[100%] md:h-[570px] h-[350px]">
              <motion.img
                whileHover={{ scale: 1.03 }}
                src={HOME.INFO_IMG}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="row-span-2 w-full flex justify-center md:justify-end ">
            <div className="w-full flex flex-col gap-2 pb-5 sm:pb-0 pt-10 lg:w-[95%]  2xl:[100%] xl:w-[90%] ">
              <motion.p
                initial="hidden"
                whileInView="reveal"
                transition={{ staggerChildren: 0.02 }}
                className="lg:text-4xl text-3xl font-felixTitling"
              >
                {array.map((char, index) => (
                  <motion.span
                    key={index}
                    variants={charVariants}
                    transition={{ duration: 0.5 }}
                  >
                    {char.heading}
                  </motion.span>
                ))}
              </motion.p>
              <motion.p
                initial="hidden"
                whileInView="reveal"
                transition={{ staggerChildren: 0.02 }}
                className="md:text-2xl lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7"
              >
                {array.map((char, index) => (
                  <motion.span
                    key={index}
                    variants={charVariants}
                    transition={{ duration: 0.5 }}
                  >
                    {char.textdata}
                  </motion.span>
                ))}
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
