import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const BLOGS = {
  ADD(req, token) {
    const url = `${CONSTANT.URL}blog`;
    return API_REQUEST("POST", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  UPDATE(req, id) {
    const url = `${CONSTANT.URL}blog/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
    });
  },
  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}blog/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}blog`;
    return API_REQUEST("GET", url);
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}blog/${id}`;
    return API_REQUEST("GET", url);
  },
};
