import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BLOGS } from "../../../api/blogs"; // Your blogs API
import { UseAuth } from "../../../utils/UseAuth";
import { AuthContext } from "../../../utils/AuthContext";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

export default function BlogView() {
  const IMG_PATH = "/dbImages/blogs";
  const { checkAccessTokenValidity } = UseAuth();
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    content: "",
    author: "",
    blogImage: "",
    imgView: "",
  });

  const getBlog = async (id) => {
    try {
      const res = await BLOGS.GET_BY_ID(id); // Fetch blog data by ID
      if (res.status !== 200) {
        throw new Error("Failed to fetch blog information");
      }
      setData({
        ...data,
        _id: res.data._id,
        title: res.data.title,
        content: res.data.content,
        author: res.data.author,
        blogImage: IMG_PATH + res.data.blogImage,
      });
    } catch (error) {
      console.error("Error loading blog: ", error);
    }
  };

  useEffect(() => {
    getBlog(id);
  }, [id]);

  return (
    <div>
      <Breadcrumbs pageName="View Blog" />
      <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            {data.title}
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
          <div className="flex flex-col gap-9 ">
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Title <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.title}
                </p>
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Content <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.content}
                </p>
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Author <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.author}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-9 h-auto">
            <div className="p-6.5 h-auto">
              <div className="mb-8 flex flex-col gap-6 xl:flex-row ">
                <div className="w-full ">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Blog Image
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex items-center justify-center w-full">
                    <label
                      className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                    >
                      <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                        <img
                          src={data.blogImage}
                          alt="Blog"
                          className="h-full object-contain"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
