import React from 'react'
// import Footer from '../pages/unAuth/home/Footer'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

export default function BasicLayout({ children }) {
  return (
    <div>
      <div className="w-full z-10">
        <Header />
      </div>
      <div>{children}</div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  )
}
