import React from 'react'
import { MENU } from "../../assets";

export default function ComingSoon() {
    return (
        <div className="h-full w-full flex justify-center">
            <img src={MENU.COMING_SOON} alt="" className="w-full object-contain"></img>
        </div>
    )
}
