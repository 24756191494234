import React, { useEffect } from 'react';
import { ICONS, IMG } from "../../assets";
import splitStringUsingRegex from "../../utils/splitStringUsingRegex";
import { motion, Variants } from "framer-motion";

export default function Facilities() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const facilities = [
    {
      "id": 1,
      "facility": "Air-Conditioned Rooms",
      "image": ICONS.I_AC
    },
    {
      "id": 2,
      "facility": "Multi-Cuisine Restaurants",
      "image": ICONS.I_CUT
    },
    {
      "id": 3,
      "facility": "All Major Credit Cards Accepted",
      "image": ICONS.I_ATM
    },
    {
      "id": 4,
      "facility": "International Direct Dialing",
      "image": ICONS.I_ISD
    },
    {
      "id": 5,
      "facility": "Minibar in Rooms",
      "image": ICONS.I_MB
    },
    {
      "id": 6,
      "facility": "Boardroom",
      "image": ICONS.I_BOARD
    },
    {
      "id": 7,
      "facility": "Business Centre",
      "image": ICONS.I_BUSI
    },
    {
      "id": 8,
      "facility": "Permit Room",
      "image": ICONS.I_PR
    },
    {
      "id": 9,
      "facility": "Swimming Pool",
      "image": ICONS.I_SWIM
    },
    {
      "id": 10,
      "facility": "Ayurveda",
      "image": ICONS.I_AYUR
    },
    {
      "id": 11,
      "facility": "Chauffeur Accommodation",
      "image": ICONS.I_DRIVER
    },
    {
      "id": 12,
      "facility": "Backwater Cruise Arrangements",
      "image": ICONS.I_BC
    },
    
    {
      "id": 13,
      "facility": "Travel Assistance",
      "image": ICONS.I_SE
    },
    {
      "id": 14,
      "facility": "Conference Hall",
      "image": ICONS.I_CONF
    },
    {
      "id": 15,
      "facility": "Open Air Lawn",
      "image": ICONS.I_PARK
    },
    {
      "id": 16,
      "facility": "Safe Deposit Lockers",
      "image": ICONS.I_SAFE
    },
  ]
  const amenities = [
    {
      "id": 1,
      "name": "Money Exchange",
      "image": ICONS.I_AC
    },
    {
      "id": 2,
      "name": "Doctor on Call",
      "image": ICONS.I_CUT
    },
    {
      "id": 3,
      "name": "Free Wi-Fi",
      "image": ICONS.I_MB
    },
    {
      "id": 4,
      "name": "Business Support",
      "image": ICONS.I_BUSI
    },
    {
      "id": 5,
      "name": "24-Hour Room Service",
      "image": ICONS.I_ATM
    },
    {
      "id": 6,
      "name": "Business Amenities",
      "image": ICONS.I_BUSI
    },
    {
      "id": 7,
      "name": "Premium Guest Supplies",
      "image": ICONS.I_CONF
    },
    {
      "id": 8,
      "name": "Laundry & Dry Cleaning",
      "image": ICONS.I_AYUR
    },
    {
      "id": 9,
      "name": "Travel and Sightseeing",
      "image": ICONS.I_SE
    },
    {
      "id": 10,
      "name": "Premium Guest Assistance",
      "image": ICONS.I_PR
    }
  ]
  const activities = [
    {
      "id": 1,
      "title": "Angling",
      "imgUrl": ICONS.I_AC,
    },
    {
      "id": 2,
      "title": "Swimming Pool",
      "imgUrl": ICONS.I_SWIM,
    },
    {
      "id": 3,
      "title": "Canoeing",
      "imgUrl": ICONS.I_CUT,
    },
    {
      "id": 4,
      "title": "Children's Play Area",
      "imgUrl": ICONS.I_PARK,
    },
    {
      "id": 5,
      "title": "Pedal Boating",
      "imgUrl": ICONS.I_ISD,
    },
    {
      "id": 6,
      "title": "Bird Watching",
      "imgUrl": ICONS.I_CONF,
    },
    {
      "id": 7,
      "title": "Indoor and Outdoor Games",
      "imgUrl": ICONS.I_PARK,
    }
    
  ];
  const text =
    "Fine Range of Facilities, Amenities and Activities";
  const textChars = splitStringUsingRegex(text);

  const charVariants = {
    hidden: { opacity: 0, y: 20 },
    reveal: { opacity: 1, y: 0 },
  };
  const fadeInAnimationVarience = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeIn",
      },
    },
  };
  return (
    <div className="h-auto w-full">
      <div className="flex flex-col">
        <div className='w-full h-full relative'>
          <div className="w-full h-full md:h-screen">
            <img
              src={IMG.FAC_BANNER}
              alt="images"
              style={{ width: "100%", height: "100%" }}
              className="object-cover"
            />
          </div>
          <div className="absolute bottom-8 md:bottom-10 text-white w-full lg:text-center">
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="w-full xl:w-1/2 mx-auto font-Dalmatins leading-[35px] md:leading-[85px] lg:leading-[90px] text-[10px] md:text-2xl lg:text-3xl text-center">
              {textChars.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char}
                </motion.span>
              ))}
            </motion.p>
          </div>
        </div>
        <div className="py-20 px-[10px] md:px-[50px] lg:px-[100px] xl:px-[200px] text-center ">
          <h1 className="text-center text-4xl font-felixTitling mb-10">FACILITIES</h1>
          <motion.div
            variants={fadeInAnimationVarience}
            initial="initial"
            whileInView="animate"
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 justify-items-center">
            {facilities.slice(0, 12).map((data) => (
              <div key={data.id} className="flex flex-col gap-2 items-center text-center space-y-2">
                <div className="w-[70px] h-[70px] p-2">
                  <img src={data.image} alt="facility" className="w-full h-auto object-cover" />
                </div>
                <p className="text-lg font-poppins text-[#AEB0B1]">{data.facility}</p>
              </div>
            ))}
          </motion.div>
          <motion.div
            variants={fadeInAnimationVarience}
            initial="initial"
            whileInView="animate" className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center mt-8 md:w-2/3 mx-auto">
            {facilities.slice(12, 16).map((data) => (
              <div key={data.id} className="flex flex-col gap-2 items-center text-center space-y-2">
                <div className="w-[70px] h-[70px] p-2">
                  <img src={data.image} alt="facility" className="w-full h-auto object-cover" />
                </div>
                <p className="text-lg font-poppins text-[#AEB0B1]">{data.facility}</p>
              </div>
            ))}
          </motion.div>
        </div>
        <div className="py-20 px-[10px] md:px-[50px] lg:px-[100px] xl:px-[200px] text-center  ">
          <h1 className="text-center text-4xl font-felixTitling mb-10">AMENITIES</h1>
          <motion.div
            variants={fadeInAnimationVarience}
            initial="initial"
            whileInView="animate" className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 justify-items-center">
            {amenities.slice(0, 6).map((data) => (
              <div key={data.id} className="flex flex-col gap-2 items-center text-center space-y-2">
                <div className="w-[70px] h-[70px] p-2">
                  <img src={data.image} alt="facility" className="w-full h-auto object-cover" />
                </div>
                <p className="text-lg font-poppins text-[#AEB0B1]">{data.name}</p>
              </div>
            ))}
          </motion.div>
          <motion.div
            variants={fadeInAnimationVarience}
            initial="initial"
            whileInView="animate" className="grid grid-cols-2 md:grid-cols-3  lg:grid-cols-4 gap-4 justify-items-center mt-8 md:w-2/3 mx-auto">
            {amenities.slice(6, 10).map((data) => (
              <div key={data.id} className="flex flex-col gap-2 items-center text-center space-y-2">
                <div className="w-[70px] h-[70px] p-2">
                  <img src={data.image} alt="facility" className="w-full h-auto object-cover" />
                </div>
                <p className="text-lg font-poppins text-[#AEB0B1]">{data.name}</p>
              </div>
            ))}
          </motion.div>
        </div>
        <div className="py-20 px-[10px] md:px-[50px] lg:px-[100px] xl:px-[200px] text-center  ">
          <h1 className="text-center text-4xl font-felixTitling mb-10">ACTIVITIES</h1>
          <motion.div
            variants={fadeInAnimationVarience}
            initial="initial"
            whileInView="animate"
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 justify-items-center">
            {activities.slice(0, 6).map((data) => (
              <div key={data.id} className="flex flex-col gap-2 items-center text-center space-y-2">
                <div className="w-[70px] h-[70px] p-2">
                  <img src={data.imgUrl} alt="facility" className="w-full h-auto object-cover" />
                </div>
                <p className="text-lg font-poppins text-[#AEB0B1]">{data.title}</p>
              </div>
            ))}
          </motion.div>
          <motion.div
            variants={fadeInAnimationVarience}
            initial="initial"
            whileInView="animate" className="flex justify-center mt-8">
            {activities.slice(6, 7).map((data) => (
              <div key={data.id} className="flex flex-col gap-2 items-center text-center space-y-2">
                <div className="w-[70px] h-[70px] p-2">
                  <img src={data.imgUrl} alt="facility" className="w-full h-auto object-cover" />
                </div>
                <p className="text-lg font-poppins text-[#AEB0B1]">{data.title}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  )
}
