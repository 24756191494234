import React, { useEffect } from 'react'
import { IMG } from "../../assets";

export default function Terms() {
    useEffect(() => {
        // Scroll to top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="h-auto w-full">
            <div className=" w-full h-full md:h-150">
                <img
                    src={IMG.TERMS_IMG}
                    alt="images"
                    style={{ width: "100%", height: "100%" }}
                    className=" object-cover"
                />
            </div>
            <div className="w-full  h-full flex flex-col gap-5 justify-center items-center py-10 px-50">
                <div className="w-full h-full flex justify-center items-center">
                    <p className="text-blue font-felixTitling font-bold text-xl sm:text-4xl md:text-6xl text-center">
                        Terms and Conditions
                    </p>
                </div>
                <p className='font-poppins text-sm'>
                    Nam auctor tempus dolor vitae ullamcorper. Vivamus in venenatis nunc. Fusce venenatis arcu non vestibulum sodales. Integer convallis pellentesque interdum. Quisque ornare nulla eget congue vestibulum. Phasellus congue congue diam a varius. Sed imperdiet magna vel tellus pellentesque mattis. Ut sed justo semper, cursus mauris eget, varius felis. Nunc maximus gravida consequat. Donec eu neque malesuada, aliquam leo consequat, elementum arcu. Aenean ultrices, lorem sed finibus aliquam, turpis ex fringilla dolor, non tempor nisi eros et dui. Nullam orci nunc, malesuada ut porta et, volutpat egestas leo. Duis auctor interdum neque eu finibus. Nam quis eros quis quam rutrum lacinia. Integer accumsan dolor sapien, sed semper turpis pulvinar sit amet.
                </p>
                <p className='font-poppins text-sm'>
                    Nam auctor tempus dolor vitae ullamcorper. Vivamus in venenatis nunc. Fusce venenatis arcu non vestibulum sodales. Integer convallis pellentesque interdum. Quisque ornare nulla eget congue vestibulum. Phasellus congue congue diam a varius. Sed imperdiet magna vel tellus pellentesque mattis. Ut sed justo semper, cursus mauris eget, varius felis. Nunc maximus gravida consequat. Donec eu neque malesuada, aliquam leo consequat, elementum arcu. Aenean ultrices, lorem sed finibus aliquam, turpis ex fringilla dolor, non tempor nisi eros et dui. Nullam orci nunc, malesuada ut porta et, volutpat egestas leo. Duis auctor interdum neque eu finibus. Nam quis eros quis quam rutrum lacinia. Integer accumsan dolor sapien, sed semper turpis pulvinar sit amet.
                </p>
                <p className='font-poppins text-sm'>
                    Nam auctor tempus dolor vitae ullamcorper. Vivamus in venenatis nunc. Fusce venenatis arcu non vestibulum sodales. Integer convallis pellentesque interdum. Quisque ornare nulla eget congue vestibulum. Phasellus congue congue diam a varius. Sed imperdiet magna vel tellus pellentesque mattis. Ut sed justo semper, cursus mauris eget, varius felis. Nunc maximus gravida consequat. Donec eu neque malesuada, aliquam leo consequat, elementum arcu. Aenean ultrices, lorem sed finibus aliquam, turpis ex fringilla dolor, non tempor nisi eros et dui. Nullam orci nunc, malesuada ut porta et, volutpat egestas leo. Duis auctor interdum neque eu finibus. Nam quis eros quis quam rutrum lacinia. Integer accumsan dolor sapien, sed semper turpis pulvinar sit amet.
                </p>

            </div>
        </div>
    )
}
