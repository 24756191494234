import React, { useState } from 'react'
import { GALLERY, HOME } from "../../assets";
export default function Gallery() {
    const [show, setShow] = useState(true)
  
    const gallery = [
        {
            "id": 1,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_1,
        },
        {
            "id": 2,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_2,
        },
        {
            "id": 3,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_3,
        },
        {
            "id": 4,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_4,
        },
        {
            "id": 5,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_5,
        },
        {
            "id": 6,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_6,
        },
        {
            "id": 7,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_7,
        },
        {
            "id": 8,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_8,
        },
        {
            "id": 9,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_9,
        },
        {
            "id": 10,
            "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
            imgUrl: GALLERY.GAL_10,
        },
        // {
        //     "id": 11,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_11,
        // },
        // {
        //     "id": 12,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_12,
        // },
        // {
        //     "id": 13,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_13,
        // },
        // {
        //     "id": 14,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_14,
        // },
        // {
        //     "id": 15,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_15,
        // },
        // {
        //     "id": 16,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_16,
        // },
        // {
        //     "id": 17,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_17,
        // },
        // {
        //     "id": 18,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_18,
        // },
        // {
        //     "id": 19,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_19,
        // },
        // {
        //     "id": 20,
        //     "description": " Cras aliquet est eu lacinia venenatis. Curabitur vitae auctor metus. ",
        //     imgUrl: GALLERY.GAL_20,
        // },
    ];
    return (
        <div className="h-auto w-full">
            <div>
                <div className=" w-full h-full md:h-100">
                    <img
                        src={GALLERY.GAL_B}
                        alt="images"
                        style={{ width: "100%", height: "100%" }}
                        className=" object-cover"
                    />
                </div>
                <div className="w-full py-20 px-10 lg:px-30 bg-[#fff]">
                    <div className=' w-full h-auto bg-[#fff] flex justify-center items-center '>
                        <div className=' h-auto w-[300px] sm:w-[600px]  md:w-[600px] grid sm:grid-cols-2 grid-cols-1 sm:pt-4'>
                            <div className={`${!show ? " bg-[#ADD8E6]  " : 'bg-primary text-[#fff]'} grid justify-center items-center sm:rounded-t-lg h-13 py-3 w-full`} onClick={() => { setShow(true) }}>
                                <button className={`${!show ? "text-primary" : 'text-[#fff] '} font-robotoMd font-medium `} onClick={() => { setShow(true) }}>VIDEO GALLERY</button>
                            </div>
                            <div className={`${show ? "bg-[#ADD8E6]  " : 'bg-primary text-[#fff]'} w-auto grid justify-center items-center sm:rounded-t-lg h-13 py-3`} onClick={() => { setShow(false) }} >
                                <button className={`${show ? "text-primary" : 'text-[#fff]'} font-robotoMd font-medium`} onClick={() => { setShow(false) }}>IMAGE GALLERY</button>
                            </div>
                        </div>

                    </div>
                    <div className="mt-10 flex flex-col gap-10 justify-center items-center">
                    {show ?
                        <div className='w-[100%] lg:w-[80%] justify-center h-auto rounded-lg shadow-md p-2 lg:p-3'>
                            <video className="rounded-md w-full h-auto object-cover" autoPlay muted controls>
                                <source src={HOME.VIDEO} type="video/mp4" />
                            </video>
                        </div>
                        :
                        <div className='w-full h-auto'>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {gallery.map((gallery, index) => (
                                <div key={index} className="w-full">
                                    <div className="">
                                        <div className='w-[100%] h-auto rounded-lg'>
                                            <img className="w-full h-auto object-cover rounded-md" src={gallery.imgUrl} alt='img' />
                                        </div>
                                        <div className="p-4">
                                            <span className="text-[#00000]">{gallery.description}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        </div>
                    }
                    </div>
                   
                    {/* <div className="mt-10 flex flex-col gap-10 justify-center items-center">
                        <div className='w-[100%] lg:w-[80%] justify-center h-auto rounded-lg shadow-md p-2 lg:p-3'>
                            <video className="rounded-md w-full h-auto object-cover" loop autoPlay muted>
                                <source src={HOME.VIDEO} type="video/mp4" />
                            </video>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {gallery.map((gallery, index) => (
                                <div key={index} className="w-full">
                                    <div className="">
                                        <div className='w-[100%] h-auto rounded-lg'>
                                            <img className="w-full h-auto object-cover rounded-md" src={gallery.imgUrl} alt='img' />
                                        </div>
                                        <div className="p-4">
                                            <span className="text-gray-400">{gallery.description}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
