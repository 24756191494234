import React from "react";
import { HOME } from "../../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "./Offers.css";

export default function Offers() {
  const slidesContent = [
    {
      id: 0,
      img: HOME.OFFERS_IMG,
      title: "Ayurvedic Wellness ",
      // title1: "NISL SITAMET DIAM FASILISIS",
      description:
        "Experience personalized therapies, traditional healing methods, and holistic care in a serene setting, tailored to your individual health needs.",
    },
    {
      id: 1,
      img: HOME.OFFERS_IMG2,
      title: "Premium Hospitality Amenities",
      // title1: "NISL SITAMET DIAM FASILISIS",
      description:
        "A range of top-tier amenities designed for your utmost comfort and convenience. From seamless business services to relaxing leisure facilities, our premium amenities guarantee a welcoming and delightful experience.",
    },
    {
      id: 2,
      img: HOME.OFFERS_IMG3,
      title: "Dining Delights ",
      // title1: "NISL SITAMET DIAM FASILISIS",
      description:
        "Indulge in a culinary journey with our exquisite dining options, offering a blend of local and international flavors in an elegant setting that makes every meal memorable.",
    },
    {
      id: 3,
      img: HOME.OFFER_IMG4,
      title: "Day Packages ",
      title1:"& Adventure Activities",
      description:
        "Enjoy our curated day-escape packages and exciting adventure activities. Whether you seek elaxation or thrills, we offer experiences that bring joy and enrichment to your holidays.",
    },
    {
      id: 4,
      img: HOME.OFFER_IMG5,
      title: "Wedding & Event Destination ",
      // title1: "NISL SITAMET DIAM FASILISIS",
      description:
        "Host memorable events with our versatile venues, designed to accommodate large gatherings. We provide comprehensive amenities, including exquisite food and beverages, to ensure every aspect of your event is seamlessly catered to and flawlessly executed.",
    },
    {
      id: 5,
      img: HOME.OFFER_IMG6,
      title: "Luxurious Stays ",
      // title1: "NISL SITAMET DIAM FASILISIS",
      description:
        "Each space is thoughtfully designed to offer comfort, sophistication, and tailored amenities for both leisure and business travelers. Each room is meticulously crafted to provide an exceptional blend of comfort, elegance, and personalized amenities, catering perfectly to both leisure and business travelers.",
    },
  ];
  return (
    <div className="w-full h-auto flex items-center justify-center flex-col xl:pt-20 pb-20">
      <div className="w-full h-auto flex justify-center items-center">
        <div className="w-[95%] md:w-[80%] h-auto max-w-screen-2xl">
          <div className="xl:w-[42%] lg:w-[52%] w-auto mb-10">
            <p className="2xl:text-5xl lg:text-4xl md:text-4xl text-3xl font-felixTitling mb-4">
              Our Signature Experiences
            </p>
            <p className="lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7">
              A wide range of services that cater to every need, from luxurious accommodations and
              rejuvenating Ayurvedic therapies to exquisite dining and exciting day-out activities. Our
              commitment to hospitality ensures that every guest enjoys a truly exceptional and personalized
              experience
            </p>
          </div>
          {/* <div className="flex items-end justify-end w-[80%] h-auto mx-45 bg-red">
            <div className="w-[750px] h-[650px]">
              <img
                src={HOME.OFFERS_IMG}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="cols-span-1 w-full h-full mb-15">
              <div className="w-full flex flex-col justify-end pl-14 pr-20">
                <p className="w-full text-2xl  font-felixTitling ">
                  SUSPENDISSE DIGNISSIM <br /> NISL SITAMET DIAM FASILISIS
                </p>
                <p className="lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7 mt-5 mb-18">
                  Duis ut egestas dolor. Sed vel tellus pulvinar, sodales diam
                  viverra, luctus leo. Suspendisse dignissim nisl sit amet diam
                  facilisis, ac
                </p>
                <div className="flex items-center justify-between w-[31%] bg-[#195FAB] text-[#fff] p-3">
                  <button className="font-poppins font-extralight">
                    ENQUIRE NOW
                  </button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25 25"
                    fill="#fff"
                    width={20}
                    height={20}
                  >
                    <g id="Right-2" data-name="Right">
                      <polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div> */}
          <div className="carousel-container w-full h-auto">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              centeredSlides={true}
              followFinger={true}
              grabCursor={true}
              loop={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper custom-pagination "
            >
              {slidesContent.map((slides) => (
                <SwiperSlide key={slides.id}>
                  <div className="lg:flex items-end justify-end lg:w-[90%] h-auto xl:ml-28 lg:ml-15  pb-[10%]">
                    <div className="w-full md:w-[850px] h-[450px] md:h-[650px] p-2 lg:p-0 ">
                      <img
                        src={slides.img}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="cols-span-1 w-full h-full mb-10 lg:px-15 px-8  lg:pt-0 pt-10">
                      {/* <div className="w-full flex flex-col pl-14 pr-20 bg-hero"> */}
                      <p className="w-full text-2xl font-felixTitling">
                        {slides.title}
                        <p>{slides.title1}</p>
                      </p>
                      <p className="lg:text-xl text-lg font-poppins text-[#0d1316] font-extralight leading-7 mt-5 mb-18">
                        {slides.description}
                      </p>
                      <button className="flex items-center justify-between w-auto bg-[#195FAB] text-[#fff] px-4 py-3">
                        <p className="font-poppins font-extralight mr-12">
                          ENQUIRE NOW
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 25 25"
                          fill="#fff"
                          width={20}
                          height={20}
                        >
                          <g id="Right-2" data-name="Right">
                            <polygon points="17.5 5.999 16.793 6.706 22.086 11.999 1 11.999 1 12.999 22.086 12.999 16.792 18.294 17.499 19.001 24 12.499 17.5 5.999" />
                          </g>
                        </svg>
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}

              {/* Add more SwiperSlide components here for additional slides */}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
