import React from "react";

export default function ProfileView({ data }) {
  const checkRole = (role) => {
    var rtnVal = "";
    if (role === 0) {
      rtnVal = "Super admin";
    } else if (role === 1) {
      rtnVal = "admin";
    } else if (role === 2) {
      rtnVal = "Booking clerk";
    } else if (role === 3) {
      rtnVal = "Ticket inspector";
    }
    return rtnVal;
  };
  return (
    <div className="grid grid-cols-1 gap-9 sm:grid-cols-2 ">
      <div className="flex flex-col gap-9 ">
        <div className="p-6.5">
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              User name<span className="text-meta-1">*</span>
            </label>
            <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
              {data.name}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-9 h-auto">
        <div className="p-6.5">
          <div className="mb-4.5">
            <label className="mb-2.5 block text-black dark:text-white">
              User Role <span className="text-meta-1">*</span>
            </label>
            <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
              {checkRole(data.role)}
            </p>
          </div>
        </div>
      </div>
      <div className="p-6.5"></div>
    </div>
  );
}
