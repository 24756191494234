import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { ROUTE } from "../../../api/route";

export default function ViewRoute() {
  const { id } = useParams();
  const [data, setData] = useState({
    url: "",
    route: "",
    menu: "",
    imgView: "",
    isPurged: false,
  });

  const getRouteById = async (id) => {
    try {
      const res = await ROUTE.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch events information");
      }
      console.log("s", res.data.menu);
      setData({
        _id: res.data._id,
        url: res.data.url,
        route: res.data.route,
        menu: res.data.menu,
        imgView: `/menu/${res.data.menu}`,
        qr: `/qr/${res.data.qr}`,
        isPurged: res.data.isPurged,
      });
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    getRouteById(id);
    console.log("menu", data.menu);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="Update Route" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Update Details
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-9 sm:grid-cols-2 ">
          <div className="flex flex-col gap- ">
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Route Path <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.route}
                </p>
              </div>
            </div>
            <div className="px-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Qr Image <span className="text-meta-1">*</span>
                </label>
                <label
                  htmlFor="image"
                  className="flex flex-col items-center justify-center w-[60%] h-100 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                >
                  <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                    <img
                      src={data.qr}
                      alt="images"
                      unoptimized
                      width={0}
                      height={0}
                      sizes="100vw"
                      style={{ width: "80%", height: "80%" }}
                      className=" object-contain"
                      // className="h-full object-contain"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="p-6.5">
              <div className="mb-4.5 flex gap-3">
                <p className="italic">URL is going to be:</p>
                <p>{data.url}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-9 p-6.5">
            <div className=" w-full">
              <label className="mb-2.5 block text-black dark:text-white">
                Upload Image
                <span className="text-meta-1">*</span>
              </label>
              <label
                htmlFor="image"
                className="flex flex-col items-center justify-center w-full h-100 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
              >
                <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                  <img
                    src={data.imgView}
                    alt="images"
                    unoptimized
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: "100%", height: "100%" }}
                    className=" object-contain"
                    // className="h-full object-contain"
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
