import React, { useEffect } from "react";
import Hero from "./Hero";
import Blog from "./Blog";
// import Footer from "./Footer";
import Banner from "./Banner";
import Banner2 from "./Banner2";
import Package from "./Package";
import Offers from "./Offers";
import InfoSection from "./InfoSection";

export default function HomeScreen() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='w-full h-auto flex flex-col'>
      <Hero />
      <InfoSection />
      <Banner />
      <Banner2 />
      <Package />
      <Offers />
      <Blog />
      {/* <Footer /> */}
    </div>
  );
}
