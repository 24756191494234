import "./App.css";
import { AuthContext } from "./utils/AuthContext";
import { useMemo, useState } from "react";
import Configuration from "./layout/Configuration";
import { TokenContext } from "./utils/TokenContext";
import { TempContext } from "./utils/TempContext";
import { BookingContext } from "./utils/BookingContext";

function App() {
  const [user, setUser] = useState(null);
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  const [token, setToken] = useState(null);
  const tokenValue = useMemo(() => ({ token, setToken }), [token, setToken]);

  const [temp, setTemp] = useState("null");
  const tempValue = useMemo(() => ({ temp, setTemp }), [temp, setTemp]);

  const [session, setSession] = useState("null");
  const sessionValue = useMemo(
    () => ({ session, setSession }),
    [session, setSession]
  );
  return (
    <>
      <AuthContext.Provider value={userValue}>
        <TokenContext.Provider value={tokenValue}>
          <TempContext.Provider value={tempValue}>
            <BookingContext.Provider value={sessionValue}>
              <Configuration />
            </BookingContext.Provider>
          </TempContext.Provider>
        </TokenContext.Provider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
