import React from "react";

export default function NotAllowed() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <p className="text-primary text-6xl font-bold">
        You are not allowed here!
      </p>
      <p className="text-primary text-3xl font-bold mt-5">Please go back</p>
    </div>
  );
}
