import React from 'react'
import { ICONS, IMG } from "../../assets";

export default function Amenities() {
  const amenities = [
      {
        "id": 1,
        "name": "Money Exchange",
        "image":  ICONS.I_RS
      },
      {
        "id": 2,
        "name": "Doctor on Call",
        "image":  ICONS.I_DOCT
      },
      {
        "id": 3,
        "name": "Free Wi-Fi",
        "image": ICONS.I_WIFI
      },
      {
        "id": 4,
        "name": "24-Hour Room Service",
        "image":  ICONS.I_ROOM
      },
      {
        "id": 5,
        "name": "Laundry & Dry Cleaning",
        "image":  ICONS.I_LAUN
      },
      {
        "id": 6,
        "name": "Business Amenities",
        "image":  ICONS.I_BUSI
      },
      {
        "id": 7,
        "name": "Premium Guest Supplies",
        "image":  ICONS.I_CHECK
      },
      {
        "id": 8,
        "name": "Business Support",
        "image": ICONS.I_BUSI
      },
      {
        "id": 9,
        "name": "Travel and Sightseeing",
        "image":  ICONS.I_SE
      },
      {
        "id": 10,
        "name": "Premium Guest Assistance",
        "image":  ICONS.I_RECEP
      }
    ]
  
  return (
    <div className='h-auto w-full'>
      <div className='flex flex-col'>
        <div className=" w-full h-full md:h-100">
          <img
            src={IMG.FAC_BANNER}
            alt="images"
            style={{ width: "100%", height: "100%" }}
            className=" object-cover"
          />
        </div>
        <div className='w-full h-auto px-[100px] py-10'>
          <div className='pb-10'>
            <p className='font-felixTitling text-3xl text-center' >AMENITIES</p>
          </div>
          <div className='bg-[#F5FFFA] rounded-md flex flex-col p-10'>

            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
              {amenities.map((data) => (
                <div className='flex flex-col gap-3 border-2 border-[#DCDCDC] shadow-md rounded-md justify-center items-center py-5'>
                  <div className=' w-[100px] h-[100px]'>
                    <img src={data.image} alt='facility' className='w-full h-full object-cover' />
                  </div>
                  <p>{data.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
